import img01 from "../assets/images/olympo36/img01.jpg"
import img02 from "../assets/images/olympo36/img02.jpg"
import img03 from "../assets/images/olympo36/img03.jpg"
import img04 from "../assets/images/olympo36/img04.jpg"
import img05 from "../assets/images/olympo36/img05.jpg"
import img06 from "../assets/images/olympo36/img06.jpg"
import img07 from "../assets/images/olympo36/img07.jpg"
import img08 from "../assets/images/olympo36/img08.jpg"
import img09 from "../assets/images/olympo36/img09.jpg"
import img10 from "../assets/images/olympo36/img10.jpg"
import img11 from "../assets/images/olympo36/img11.jpg"
import img12 from "../assets/images/olympo36/img12.jpg"
import img13 from "../assets/images/olympo36/img13.jpg"
import img14 from "../assets/images/olympo36/img14.jpg"
import img15 from "../assets/images/olympo36/img15.jpg"
import img17 from "../assets/images/olympo36/img17.jpg"
import img18 from "../assets/images/olympo36/img18.jpg"
export const OlympoBlock1 = [
  {
    title: `ACABADO TECHO`,
    text: `El techo del sal&oacute;n de pasajeros es decorado con material PVC DECORATIVO que es impermeable, de facil limpieza, antinflamable y aislante t&eacute;rmico.`,
    img: img01,
  },
  {
    title: `ACABADO LATERALES`,
    text: ` Decorado con material PVC DECORATIVO que es impermeable, de facil limpieza, antinflamable y aislante t&eacute;rmico. Perfilado de marcos de ventanas con PVC DECORATIVO de brillo natural.`,
    img: img02,
  },
  {
    title: `ACABADO DE PISO`,
    text: `El piso es cubierto en PVC FLEXIBLE DE ALTO TR&Aacute;NSITO de alta resistencia al desgaste. Opciones de colores para el decorado del piso. Es antinflamable y retardante.`,
    img: img03,
  },
  {
    title: `ILUMINACI&Oacute;N`,
    text: ` Iluminaci&oacute;n con tecnolog&iacute;a LED a ambos lados del sal&oacute;n en la parte superior (techo).`,
    img: img04,
  },
  {
    title: `PAQUETERAS`,
    text: ` LIGERAS y elaboradas en poliuretano rigido de alta densidad. Fijadas en el techo estrat&eacute;gicamente para EVITAR VIBRACIONES. Luces de lectura individuales con iluminaci&oacute;n independiente. `,
    img: img05,
  },
]
export const OlympoBlock2 = [
  {
    title: `C&Uacute;PULA`,
    text: `Gravado natural con lineas estelizadas decorativas y definici&oacute;n de contornos para la colocaci&oacute;n de luces de cabina, parlantes, entre otros.  Iluminaci&oacute;n con sistema LED.`,
    img: img06,
  },
  {
    title: `MAMPARA DIVISORIA`,
    text: `Diseño decorativo y en armonia con los espacios que divide . Su ubicaci&oacute;n obedece a la optimizaci&oacute;n entre el espacio del sal&oacute;n de pasajeros y la cabina de conducci&oacute;n.`,
    img: img07,
  },
  {
    title: `TABLERO DE CONTROL`,
    text: `Diseñado para FACILITAR LA OPERACI&Oacute;N de los diferentes mandos de control de carrocer&iacute;a y chasis; diseño AERODIN&Aacute;MICO. Fabricado en fibra de vidrio. Protecci&oacute;n externa con poliuretano.`,
    img: img08,
  },
  {
    title: `GRADA DE INGRESO`,
    text: `Con ILUMINACI&Oacute;N PROPIA y fabricado en fibra de vidrio. Base estructural reforzado. `,
    img: img09,
  },
]
export const OlympoBlock3 = [
  {
    title: `FRONTAL`,
    text: ` Modelo AERODIN&Aacute;MICO. Laminado en fibra de vidrio con refuerzo interno para reducci&oacute;n de daños ocasionados por impactos. Luces LED bifocales con efecto Flash y decorativos con ilumini&oacute;n neon.`,
    img: img10,
  },
  {
    title: `POSTERIOR`,
    text: ` Modelo AERODIN&Aacute;MICO. Laminado en fibra de vidrio con refuerzo interno para reducci&oacute;n de daños ocasionados por impactos. Luces LED bifocales con efecto Flash y decorativos con ilumini&oacute;n neon.`,
    img: img11,
  },
  {
    title: `BODEGAS`,
    text: ` Livianas, forradas en aluminio. Puertas de bodegas cuentan con mecanismo de seguridad de doble traba que garantizan un sello herm&eacute;tico.  `,
    img: img12,
  },
]
export const OlympoBlock4 = [
  {
    title: `CALEFACCI&Oacute;N`,
    text: `Sistema de concentradores de calor y convecci&oacute;n forzada. Consta de radiadores y aletas de aluminio distribuidos en todo el sal&oacute;n de pasajeros; sopladores el&eacute;ctricos silenciosos, ubicados estrategicamente para un flujo homogeneo del calor en todo el sal&oacute;n de pasajeros. `,
    img: img13,
  },
  {
    title: `ASIENTOS`,
    text: [
      {
        titulo: "Asiento SOFT",
        text: `Diseño ergon&oacute;mico, suavidad en la reclinaci&oacute;n y variedad de materiales y colores.
      Inyectados en poliuretano flexible con densidad de acuerdo al tipo de servicio. Precisi&oacute;n en el armado para evitar vibraciones y uso de componentes de amortiguaci&oacute;n.`,
      }
    ],
    textAlt: `*alternativa personalizable`,
    img: img14,
  },
  {
    title: `AIRE ACONDICIONADO`,
    text: `Sistema que intercambia el aire concentrado por aire fresco del medio ambiente, purificado y climatizado a una determinada temperatura, asegurando un aire de calidad y  garantizando comodidad y bienestar a los pasajeros.`,
    textAlt: `*alternativa personalizable`,
    img: img15,
  }
]
export const OlympoBlock5=[
  {
    title:"ESPEJOS RETROVISORES",
    text:'El&eacute;ctricos con mando selector que regulan el angulo de visi&oacute;n del conductor. Cuenta con espejos calefactables para mejorar la visibilidad de conducci&oacute;n con su desempañado en zonas humedas.',
    img:img17
  },
  {
    title:'ACCIONAMIENTO PUERTA SERVICIO',
    text:'Control inalambrico para mayor seguridad de bloqueo/desbloqueo desde fuera del bus.',
    img:img18
  },
  {
    title:'PANEL DE CONTROL',
    text:'Panel digital con tecnolog&iacute;a de circuitos impresos con protecci&oacute;n “Anti – solder” que garantizan la funcionalidad correcta del bus en los diferentes climas del Per&uacute; y sin riesgo de cortocircuito. Visibilidad para la operaci&oacute;n de los componentes el&eacute;ctricos. ',
    img:img18
  }
]